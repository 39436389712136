import React from 'react'
import ServiceSidebarf from './ServiceSidebarf'
import details1f from '../../assets/images/services/services-details1f.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentf = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1f} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Plant Management </span>
                            <h3>About this Service</h3>
                            <p>The plant is one of the biggest costs for companies. In the fast-paced industry of today, a key challenge is to make equipment more reliable, which will increases productivity, cost-effectiveness, and return on investment. To avoid a mishap, prevention is required to maintain ideal working conditions for both employees and the equipment. These measures can be a planned and scheduled activity that does not interfere with daily operations. In case a failure does occur, then repair is necessary. Repair here would mean restoring the equipment to its original condition so it can work optimally again.</p>
                            <p>Our effective PM Service manages all maintenance activities in the company that is interconnected with the submodules -Production Planning, Material Management, and Sales and Distribution. Plant Maintenance service consists of key activities that include inspection, notifications, corrective and preventive maintenance, repairs, and other measures to maintain an ideal technical system. Maintenance and operations managers can use PM Service to obtain metrics on equipment performance or maintenance deployments to improve maintenance decision-making by running reports on work that has been planned, in process, or completed. Management can also budget for equipment replenishment by having accurate and up-to-date data on equipment maintenance history and relative maintenance costs.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Inspection</li>
                                            <li>Preventive Maintenance</li>
                                            <li>Repair of equipment</li>
                                            <li>Better Project Management</li>
                                            <li>Automation of Key Processes</li>
                                            <li>Equipment Maintenance Management</li>
                                            <li>Budgeting and Cost Analysisli</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Our Plant Management service provides an organization with a tool for all maintenance activities to be performed that includes plant maintenance, production costing, and repairing. Our Plant management service ensures that all machines are kept in optimal condition without interruption. In addition, our continual support also makes sure that the plant equipment is always ready for use by minimizing unanticipated production interruptions from an undiscovered state.</p>
                            <h3>Application Areas</h3>
                            <p>Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Banking Sector
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Insurance Companies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Information and technologies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Utilities
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Chemical
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Pharmaceuticals
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Retail
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Oil and Energy
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>PM</span></li>
                                <li><span>PP</span></li>
                                <li><span>MRP</span></li>
                                <li><span>Production Costing</span></li>
                                <li><span>Production resource tool (PRT)</span></li>
                                
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarf />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentf